/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface FontPickerIconProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
const FontPickerIcon: React.SFC<FontPickerIconProps> = ({size, ...props}) => (
  <svg viewBox="0 0 25 25" fill="currentColor" width={ size || "25" } height={ size || "25" } {...props}>
    <path d="M16.5 7h-8C7.673 7 7 7.673 7 8.5V11h1V8.5a.5.5 0 0 1 .5-.5H12v9H9v1h7v-1h-3V8h3.5a.5.5 0 0 1 .5.5V11h1V8.5c0-.827-.673-1.5-1.5-1.5" fill="currentColor"
    />
  </svg>
);
FontPickerIcon.displayName = 'FontPickerIcon';
export default FontPickerIcon;
/* tslint:enable */
/* eslint-enable */
